import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";
import { ConfigProvider } from 'antd';
import viVN from 'antd/locale/vi_VN';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={configureStore({})}>
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ConfigProvider theme={{ hashed: false, token: {colorSplit: 'rgba(0, 0, 0, 0.2)'} }} locale={viVN}>
          <App />
          </ConfigProvider>
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();