import React, { useState, useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    UploadOutlined,
    PlusOutlined,
    FileExcelOutlined,
    PrinterOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import {
    DatePicker,
    Col,
    Row,
    Card,
    Table,
    Tag,
    Layout,
    Divider,
    Button,
    Form,
    Select,
    AutoComplete,
    Progress,
    Space,
    Tree,
    Spin,
    Radio
} from 'antd';
import "../style.scss";
import { Pie, Column, Line, G2 } from '@ant-design/charts';
import {
    getLines,
    getKpi,
    getProducts,
    getCustomers,
    getAllMachine,
    exportKPI
} from '../../../api/ui/main';
import dayjs from 'dayjs';
import { baseURL } from '../../../config';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const KPI = (props) => {
    document.title = 'UI - KPI';
    const [listLines, setListLines] = useState([]);
    const [listMachines, setListMachines] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [selectedLine, setSelectedLine] = useState();
    const [selectedIdProduct, setSelectedIdProduct] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [params, setParams] = useState({
        start_date: dayjs().subtract(6, 'days'),
        end_date: dayjs()
    });

    const [data, setData] = useState();
    const [dataChartTiLeHoanThanhKHSX, setDataChartTiLeHoanThanhKHSX] = useState([]);
    const [dataChartTiLeNgOQC, setDataChartTiLeNgOQC] = useState([]);
    const [dataChartTiLeVanHanhThietBi, setDataChartTiLeVanHanhThietBi] = useState([]);
    const [dataChartTiLeDatThang, setDataChartTiLeDatThang] = useState([]);
    const [dataChartNgayTon, setDataChartNgayTon] = useState([]);
    const [dataChartTiLeGiaoHangDungHan, setDataChartTiLeGiaoHangDungHan] = useState([]);
    const [dataChartTiLeLoiCongDoan, setDataChartTiLeLoiCongDoan] = useState([]);
    const [dataChartLeadTime, setDataChartLeadTime] = useState([]);

    const [dataTable, setDataTable] = useState([]);
    const [columnsDate, setColumnsDate] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const count = params.end_date.diff(params.start_date, 'day');
        const colDate = [];
        if (count > 0) {
            for (let i = 0; i <= count; i++) {
                colDate.push({
                    title: params.start_date.add(i, 'day').format('DD-MM-YYYY'),
                    dataIndex: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    key: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    align: 'center'
                })
            }
        }
        setColumnsDate(colDate);
    }, [dataTable])

    const [filtChiSo, setFilterChiSo] = useState([
        {
            text: "Tỉ lệ hoàn thành kế hoạch",
            value: 'ti_le_hoan_thanh_ke_hoach'
        }, {
            text: "Tỉ lệ NG OQC",
            value: 'ti_le_ng_oqc'
        }, {
            text: "Tỉ lệ vận hành thiết bị",
            value: 'ti_le_van_hanh_thiet_bi'
        }
    ]);
    const [filtSanPham, setFiltSanPham] = useState([]);

    const columnTable = [
        {
            title: 'Tên chỉ số',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            filterfilters: filtChiSo,
            // filterMode: 'tree',
            filterSearch: true
        },
        {
            title: 'Mục tiêu',
            dataIndex: 'target',
            key: 'target',
            align: 'center'
        },
        {
            title: 'Kết quả thực tế',
            align: 'center',
            children: columnsDate
        },
        {
            title: 'Tỷ lệ đạt',
            dataIndex: 'ty_le_dat',
            key: 'ty_le_dat',
            align: 'center'
        }, {
            title: 'Tỷ lệ tăng giảm so với cùng kì năm trước',
            dataIndex: 'last_year',
            key: 'last_year',
            align: 'center'
        },
    ]

    const configTiLeHoanThanhKeHoachSanXuat = {
        legend: false,
        data: dataChartTiLeHoanThanhKHSX,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configTiLeNgOQC = {
        legend: false,
        data: dataChartTiLeNgOQC,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configTiLeVanHanhThietBi = {
        legend: false,
        data: dataChartTiLeVanHanhThietBi,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configTiLeDatThang = {
        legend: false,
        data: dataChartTiLeDatThang,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configLeadTime = {
        legend: false,
        data: dataChartLeadTime,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };

    const configTiLeLoiCongDoan = {
        legend: false,
        data: dataChartTiLeLoiCongDoan,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configTiLeGiaoHangDungHan = {
        legend: false,
        data: dataChartTiLeGiaoHangDungHan,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };
    const configNgayTon = {
        legend: false,
        data: dataChartNgayTon,
        height: 200,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        xAxis: {
            tickCount: 5
        },
        yAxis: {
            label: {
                formatter: (v) => v + "%"
            }
        }
    };

    const [selectedMachines, setSelectedMachines] = useState([]);
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        setTreeData(listLines.map(e => {
            let child = e.machine?.map(i => {
                return { title: i.name, key: i.id }
            })
            return {
                ...e,
                title: e.name,
                key: e.id,
                children: child
            }
        }));
    }, [listLines])

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };
    const onSelect = (selectedKeysValue, info) => {
        setSelectedKeys(selectedKeysValue);
    };

    useEffect(() => {
        btn_click();
    }, [])

    async function btn_click() {
        setLoading(true);
        const res = await getKpi(params);
        if (res.success) {
            const data = [];
            Object.keys(res.data).map((key, index) => {
                data.push({
                    ...res.data[key],
                    ...res.data[key].data,
                    last_year: '-'
                })
            });
            setDataTable(data);

            let list1 = [];
            Object.keys(res.data.ti_le_sx.data ?? {}).map((key, index) => {
                list1.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_sx.data[key]),
                    type: "TL Hoàn thành KHSX"
                });
                list1.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 82,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeHoanThanhKHSX(list1);
            let list2 = [];
            Object.keys(res.data.ti_le_dat_thang.data ?? {}).map((key, index) => {
                list2.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_dat_thang.data[key]),
                    type: "TL Đạt thẳng"
                });
                list2.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 80,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeDatThang(list2);
            let list3 = [];
            Object.keys(res.data.ti_le_ng.data ?? {}).map((key, index) => {
                list3.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ng.data[key]),
                    type: "TL NG"
                });
                list3.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 8,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeLoiCongDoan(list3);
            let list4 = [];
            Object.keys(res.data.ti_le_van_hanh_may.data ?? {}).map((key, index) => {
                list4.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_van_hanh_may.data[key]),
                    type: "TL Vận hành máy"
                });
                list4.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 75,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeVanHanhThietBi(list4);
            let list5 = [];
            Object.keys(res.data.ti_le_giao_hang_dung_han.data ?? {}).map((key, index) => {
                list5.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_giao_hang_dung_han.data[key]),
                    type: "TL Giao hàng đúng hạn"
                });
                list5.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 100,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeGiaoHangDungHan(list5);
            let list6 = [];
            Object.keys(res.data.ti_le_ton.data ?? {}).map((key, index) => {
                list6.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ton.data[key]),
                    type: "TL ngày tồn"
                });
                list6.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 90,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartNgayTon(list6);
            let list7 = [];
            Object.keys(res.data.ti_le_ng_oqc.data ?? {}).map((key, index) => {
                list7.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_ng_oqc.data[key]),
                    type: "TL NG OQC"
                });
                list7.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 1,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartTiLeNgOQC(list7);
            let list8 = [];
            Object.keys(res.data.ti_le_leadtime.data ?? {}).map((key, index) => {
                list8.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: parseInt(res.data.ti_le_leadtime.data[key]),
                    type: "TL Leadtime"
                });
                list8.push({
                    date: dayjs(key, 'YYYY-MM-DD').format('D/M'),
                    value: 95,
                    type: "Tỉ lệ tiêu chuẩn"
                })
            });
            setDataChartLeadTime(list8);
            setLoading(false);
        }
    }

    const [exportLoading, setExportLoading] = useState(false);
    const exportClick = async () => {
        setExportLoading(true);
        const res = await exportKPI(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.start_date, dateType);
        onChangeDate('end_date', params.end_date, dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                }
                break;
            default:
                setParams({ ...params, [position]: value })
                break;
        }
    }
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.start_date} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.end_date} format={customDateFormat} picker={dateType} />
                            </Space>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className='custom-col'>
                    <Spin spinning={loading}>
                        <Row gutter={
                            [8, 8]
                        }>
                            {/* <Row gutter={[8, 8]}> */}
                            <Col span={6}>
                                <Card title="Tỉ lệ hoàn thành kế hoạch"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeHoanThanhKeHoachSanXuat} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Tỉ lệ đạt thẳng"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeDatThang} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Tỉ lệ lỗi công đoạn"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeLoiCongDoan} />
                                </Card>
                            </Col>

                            <Col span={6}>
                                <Card title="Tỉ lệ vận hành thiết bị"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeVanHanhThietBi} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="LeadTime"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configLeadTime} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Tỉ lệ giao hàng đúng hạn"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeGiaoHangDungHan} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Ngày tồn"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configNgayTon} />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Tỉ lệ NG OQC"
                                    style={
                                        { padding: '0px' }
                                    }>
                                    <Line {...configTiLeNgOQC} />
                                </Card>
                            </Col>
                            {/* </Row> */}
                            <Col span={24}>
                                <Card style={
                                    { height: '100%' }
                                }
                                    title="Bảng thông tin chi tiết các chỉ số KPI"
                                    extra={<Button
                                        style={{ marginLeft: '15px' }}
                                        type="primary"
                                        loading={exportLoading}
                                        onClick={exportClick}>
                                        Xuất Excel</Button>}>
                                    <Table size='small' bordered
                                        pagination={false}
                                        scroll={
                                            {
                                                x: '200%',
                                                y: '50vh'
                                            }
                                        }
                                        columns={columnTable}
                                        dataSource={dataTable} />

                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                </Col>
            </Row>

        </React.Fragment >
    );
};

export default KPI;
