import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Spin, Space, Modal, Typography } from 'antd';
import { Pie } from '@ant-design/charts';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useEffect } from "react";
import { getErrorsMachine, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getThongSoMay, getPowerConsumeData, getPowerConsumeDataByMonth } from '../../../api/ui/main';
import { baseURL } from '../../../config';
import { exportThongSoMay } from '../../../api/ui/export';
import dayjs from "dayjs";

const { Sider } = Layout;
const { RangePicker } = DatePicker;


const PowerConsumeByMonth = (props) => {
    document.title = 'UI - Thông số máy'
    const [listLines, setListLines] = useState([]);
    const [listStaffs, setListStaffs] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        line_id: '',
        ca_sx: '',
        datetime: dayjs()
    });
    const [colTable, setColTable] = useState([]);
    useEffect(() => {
        // const columns = [];
        const month = [];
        for (let index = 1; index <= params.datetime.daysInMonth(); index++) {
            month.push({
                title: index.toString().padStart(2, '0'),
                key: index,
                dataIndex: index,
                align: 'center',
                render: (value) => value ?? "-",
            })
        }
        const columns = [
            {
                title: ' ',
                dataIndex: 'col',
                rowScope: 'row',
                width: '10%',
                fixed: 'left'
            },
            {
                title: 'Tháng ' + params.datetime.format('MM'),
                children: month
            }
        ];
        setColTable(columns);
    }, [params.datetime])

    const [sumPowerConsume, setSumPowerConsume] = useState(0);
    async function btn_click() {
        setLoSX();
        setLoading(true);
        var res = await getPowerConsumeDataByMonth(params);
        setData(res.data.data);
        setSumPowerConsume(res.data.sum)
        setLoading(false);
    }

    useEffect(() => {
        btn_click();
        (async () => {
            var res = await getLines();
            const options = res.data.map(e => ({ ...e, value: e.id, label: e.name }))
            setListLines(options);
            const line_in = options.find(e => e.id === 10);
            if (line_in) setParams({ ...params, line_id: line_in.id })
        })()
    }, [])

    // useEffect(() => {
    //     btn_click();
    // }, [params])
    const [loading, setLoading] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportThongSoMay({ ...params, lo_sx: loSX });
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [loSX, setLoSX] = useState();
    const onClickRow = async (record) => {
        setLoSX(record.lo_sx)
    }
    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Divider>Điều kiện truy vấn</Divider>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Form.Item label="Tháng" className='mb-3'>
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker picker='month' allowClear={false} placeholder='Chọn tháng' style={{ width: '100%' }} onChange={(value) => { setParams({ ...params, datetime: value }); setPage(1) }} value={params.datetime} />
                            </Space>
                        </Form.Item>
                        <Form.Item label="Công đoạn" className='mb-3'>
                            <Select
                                showSearch
                                value={params.line_id}
                                onChange={(value) => setParams({ ...params, line_id: value })}
                                placeholder="Chọn công đoạn"
                                options={listLines}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Card style={{ height: '100%' }} title="Quản lý điện năng tiêu thụ">
                    <Table size='small' bordered
                        loading={loading}
                        pagination={false}
                        scroll={
                            {
                                x: '150vw',
                                y: '80vh'
                            }
                        }
                        footer={() => <Typography.Text strong>{'Tổng điện năng tiêu thụ trong tháng (kW): ' + sumPowerConsume}</Typography.Text>}
                        columns={colTable}
                        dataSource={data} />
                </Card>
            </Col>
        </Row>

    </>
}

export default PowerConsumeByMonth;
