import React, { useState, useEffect } from 'react';
import { Layout, Col, Row, FloatButton, Drawer, List, Badge, Card, Avatar, Divider, Space, Typography, } from 'antd';
import ReactFullscreen from 'react-easyfullscreen';
import { FullscreenOutlined, FullscreenExitOutlined, CloseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import background1 from "../../assets/images/layout8.png";
import CommentBox from '../../components/CommentBox';
import { getMonitor, getMonitorTroubleshoot } from '../../api/db/main';
import './style.css';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const img = {
  width: '100vw',
  display: 'flex',
  height: '100vh'
}
const CanhBaoBatThuong = () => {
  document.title = 'Cảnh báo bất thường';
  const history = useHistory();
  const [clock, setClock] = useState(new Date());
  const tick = () => {
    setClock(new Date());
  };
  useEffect(() => {
    setInterval(() => tick(), 1000);
  }, []);
  const [demoMonitor, setDemoMonitor] = useState();
  const hanleGetMonitorSeparate = async () => {
    var res = await getMonitor();
    setDemoMonitor(res.data);
  }
  useEffect(() => {
    hanleGetMonitorSeparate();
    getDataMonitor();
  }, [])
  let interval1;
  useEffect(() => {
    interval1 = setInterval(async () => {
      hanleGetMonitorSeparate();
      getDataMonitor();
    }, 5000)
    return () => clearInterval(interval1);
  }, [])

  const listMC = [
    {
      code: 'GL_637CIR',
      type: 'cl',
      top: "13vh",
      left: "23vw",
    },
    {
      code: 'GL_637CIR',
      type: 'sx',
      top: "28vh",
      left: "6vw",
    },
    {
      code: 'GL_637CIR',
      type: 'tb',
      top: "19vh",
      left: "14vw",
    },
    {
      code: 'SN_UV',
      type: 'sx',
      top: "33vh",
      left: "27vw",
    },
    {
      code: 'SN_UV',
      type: 'tb',
      top: "32vh",
      left: "35vw",
    },
    {
      code: 'MK1060MF',
      type: 'sx',
      top: "36vh",
      left: "42vw",
    },
    {
      code: 'MK1060MF',
      type: 'cl',
      top: "28vh",
      left: "49vw",
    },
    {
      code: 'MK1060MF',
      type: 'tb',
      top: "31vh",
      left: "50vw",
    },
    {
      code: 'ACE70CS',
      type: 'sx',
      top: "47vh",
      left: "59vw",
    },
    {
      code: 'ACE70CS',
      type: 'cl',
      top: "30vh",
      left: "75vw",
    },
    {
      code: 'ACE70CS',
      type: 'tb',
      top: "38vh",
      left: "66vw",
    },
  ]

  const [showDrawer, setShowDrawer] = useState(true);
  const [listData, setListData] = useState([]);
  const renderItem = (item) => {
    return <List.Item>
      <List.Item.Meta
        style={{ alignItems: 'center', marginRight: '4px' }}
        avatar={<Avatar children={item.type?.toUpperCase()} style={{ backgroundColor: item?.type === 'sx' ? '#90f' : (item?.type === 'cl' ? '#AA0000' : '#00f') }} />}
        title={<span style={{ fontSize: '15px' }}>{item.content}</span>}
        description={<span style={{ color: 'black' }}><b style={{ fontSize: '14px', fontWeight: '500' }}>Cách xử lý</b> <br />{item.troubleshoot}</span>}
      />
      <div><b style={{ fontSize: '14px', fontWeight: '500' }}>Mã máy</b><br />{item.machine_id}</div>
    </List.Item>
  }
  const getDataMonitor = async () => {
    var res = await getMonitorTroubleshoot();
    setListData(res.data);
  }
  useEffect(() => {
    if (listData.length > 0) {
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
    }
  }, [listData])
  const onOpenHistory = (value) => {
    console.log(value);
    window.open('/ui/abnormal/lich-su-bat-thuong?id=' + value.id, '_blank');
  }
  return (
    <React.Fragment>

      <Layout style={{ height: '100vh', backgroundColor: '#e3eaf0' }}>
        <Row className='w-100' style={{ verticalAlign: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#2462a3', color: 'white' }}>
          <div style={{ fontSize: '22px', fontWeight: '700', }}>{clock.toLocaleString(['en-GB'], { hour12: false })}</div>
          <div style={{ fontWeight: 700, fontSize: '24px', }}>GIÁM SÁT CẢNH BÁO BẤT THƯỜNG</div>
          <Link to={'/screen'} style={{ margin: 'auto 0' }}>
            <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
          </Link>
          {/* {
                isFullCreen == false ? <FullscreenOutlined style={{ fontSize: '30px' }} onClick={() => { onRequest(); setIsFullScreen(true) }} />
                  : <FullscreenExitOutlined style={{ fontSize: '30px' }} onClick={() => { onExit(); setIsFullScreen(false) }} />
              } */}
        </Row>
        <Row style={{ width: '100%', height: '80vh' }}>
          <div style={{ position: 'relative', width: '100%', backgroundColor: 'white', float: 'right' }} >
            {demoMonitor?.length > 0 ? (
              demoMonitor.map((value) => {
                return <CommentBox title={value?.type?.toUpperCase()} content={value?.content} type={value?.type === 'sx' ? 'error' : (value?.type === 'cl' ? 'warning' : 'success')} color={value?.type === 'sx' ? '#90f' : (value?.type === 'cl' ? '#AA0000' : '#00f')} top={listMC.find((val) => val.code === value?.machine_id && val.type === value.type)?.top} left={listMC.find((val) => val.code == value?.machine_id && val.type === value.type)?.left} onClick={() => onOpenHistory(value)}></CommentBox>
              }
              )) : <></>
            }
            <img style={img} src={background1} />
          </div>
        </Row>
        {/* <a href="#" class="float-btn" onClick={() => setShowDrawer(true)}>

        </a> */}
        {showDrawer && <div className='drawer'>
          <Typography.Title level={5} className='header-drawer'>
            <Space>
              <CloseOutlined style={{marginLeft: 8}} onClick={()=>setShowDrawer(false)}/>
              Danh sách cách khắc phục
            </Space>
          </Typography.Title>
          <Divider style={{margin:0}}/>
          <List
            style={{ paddingInline: 12 }}
            dataSource={listData}
            renderItem={renderItem}
          // split={false}
          />
        </div>}
        {/* <Drawer open={showDrawer} title="Thông tin chi tiết" mask={null} onClose={() => setShowDrawer(false)} width={300} headerStyle={{ padding: 12 }} bodyStyle={{ padding: 12 }}>
          <List
            style={{ padding: 0 }}
            dataSource={listData}
            renderItem={renderItem}
          // split={false}
          />
        </Drawer> */}
      </Layout>
    </React.Fragment>
  );
};

export default CanhBaoBatThuong;