import { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Space, Spin, Dropdown, message, Radio } from 'antd';
import { Pie, Column } from '@ant-design/charts';
import { exportBMCardWarehouse, exportInventoryWarehouse, exportSummaryWarehouse, getHistoryWareHouse } from '../../../api';
import { exportWarehouse } from '../../../api/ui/export';
import { baseURL } from '../../../config';
import dayjs from "dayjs";
import { getCustomers, getDataFilterUI, getProducts } from '../../../api/ui/main';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const ThanhPhamGiay = (props) => {
    document.title = "UI - Quản lý thành phẩm giấy";
    const [dataTable, setDataTable] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        date: [dayjs(), dayjs()]
    });
    const col_detailTable = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Ngày',
            dataIndex: 'ngay',
            key: 'ngay',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Mã khách hàng',
            dataIndex: 'ma_khach_hang',
            key: 'ma_khach_hang',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'ten_khach_hang',
            key: 'ten_khach_hang',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            key: 'product_id',
            align: 'center',
            fixed: 'left',
        }, {
            title: 'Tên SP',
            dataIndex: 'ten_san_pham',
            key: 'name_product',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'ĐVT',
            dataIndex: 'dvt',
            key: 'dvt',
            align: 'center'
        }, {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center'
        }, {
            title: 'Kho',
            dataIndex: 'kho',
            key: 'kho',
            align: 'center'
        },
        {
            title: 'Mã thùng',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center'
        },
        {
            title: 'Vị trí',
            dataIndex: 'vi_tri',
            key: 'vi_tri',
            align: 'center'
        },
        {
            title: 'Nhập kho',
            children: [
                {
                    title: 'Ngày nhập',
                    dataIndex: 'ngay_nhap',
                    key: 'ngay_nhap',
                    align: 'center'
                },
                {
                    title: 'Số lượng',
                    dataIndex: 'so_luong_nhap',
                    key: 'so_luong_nhap',
                    align: 'center'
                },
                {
                    title: 'Người nhập',
                    dataIndex: 'nguoi_nhap',
                    key: 'nguoi_nhap',
                    align: 'center'
                }
            ]
        },
        {
            title: 'Xuất kho',
            children: [
                {
                    title: 'Ngày xuất',
                    dataIndex: 'ngay_xuat',
                    key: 'ngay_xuat',
                    align: 'center'
                },
                {
                    title: 'Số lượng',
                    dataIndex: 'so_luong_xuat',
                    key: 'so_luong_xuat',
                    align: 'center'
                },
                {
                    title: 'Người xuất',
                    dataIndex: 'nguoi_xuat',
                    key: 'nguoi_xuat',
                    align: 'center'
                }
            ]
        },
        {
            title: 'Tồn kho',
            width: '10%',
            children: [
                {
                    title: 'Số lượng',
                    dataIndex: 'ton_kho',
                    key: 'ton_kho',
                    align: 'center'
                },
                {
                    title: 'Số ngày tồn kho',
                    dataIndex: 'so_ngay_ton',
                    key: 'so_ngay_ton',
                    align: 'center'
                },
            ]
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            align: 'center'
        },
    ]
    useEffect(() => {
        (async () => {
            var res1 = await getCustomers();
            setListCustomers(res1.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
    }, [])

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportWarehouse(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const loadDataTable = async (params) => {
        setLoading(true)
        const res = await getHistoryWareHouse(params);
        setDataTable(res.data);
        setTotalPage(res.totalPage);
        setLoading(false);
    }
    async function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize })
    }
    useEffect(() => {
        btn_click();
    }, [])
    const [loading, setLoading] = useState(false);
    const [exportLoading1, setExportLoading1] = useState(false)
    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={exportImportWarehouse}>
                    Tổng hợp xuất nhập tồn
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={exportBMcard}>
                    BM thẻ kho
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={exportInventory}>
                    Báo cáo tồn kho
                </a>
            ),
        },
    ];
    const [messageApi, contextHolder] = message.useMessage();
    async function exportImportWarehouse() {
        setExportLoading1(true);
        var res = await exportSummaryWarehouse(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading1(false);
    }
    async function exportBMcard() {
        if (!params.ten_sp) {
            messageApi.warning('Hãy chọn sản phẩm trước');
        } else {
            setExportLoading1(true);
            var res = await exportBMCardWarehouse(params);
            if (res.success) {
                window.location.href = baseURL + res.data;
            }
            setExportLoading1(false);
        }
    }

    async function exportInventory() {
        setExportLoading1(true);
        var res = await exportInventoryWarehouse(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading1(false);
    }
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 15px' }} layout="vertical">
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                            ]}
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Khách hàng" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Chọn khách hàng"
                                onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                options={listCustomers}
                            />
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                onChange={(value) => {
                                    setParams({ ...params, ten_sp: value });
                                }}
                                placeholder="Nhập tên sản phẩm"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listNameProducts}
                            />
                        </Form.Item>
                        <Form.Item label="Lô Sản xuất" className='mb-3'>
                            <Select
                                allowClear
                                showSearch
                                placeholder="Nhập lô sản xuất"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    setParams({ ...params, lo_sx: value });
                                }}
                                options={listLoSX}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Card style={{ height: '100%' }} title="Quản lý thành phẩm giấy" extra={
                    <Space>
                        <Dropdown menu={{ items }}>
                            <Button type='primary' loading={exportLoading1}>Xuất báo cáo</Button>
                        </Dropdown>
                        <Button type='primary' loading={exportLoading} onClick={exportFile}>Xuất excel</Button>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            pagination={{
                                current: page,
                                size: 'default',
                                total: totalPage,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize)
                                }
                            }}
                            scroll={
                                {
                                    x: '130vw',
                                    y: '56vh'
                                }
                            }
                            columns={col_detailTable}
                            dataSource={dataTable} />
                    </Spin>
                </Card>
            </Col>
        </Row>
    </>
}

export default ThanhPhamGiay;