import { Button, Card, Col, DatePicker, Divider, Form, Input, Layout, Radio, Row, Select, AutoComplete, Table, Tabs, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { Line } from '@ant-design/plots';
import { getErrors, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getOQC, getDataFilterUI, getQCErrorList, getIQCList } from '../../../api/ui/main';
import { exportIQC, exportOQC, exportQCErorList } from '../../../api/ui/export';
import { baseURL } from '../../../config';
import dayjs from 'dayjs';

const { Sider } = Layout;
const { RangePicker } = DatePicker;


const QualityIQC = (props) => {
    document.title = "UI - IQC";
    const [listLines, setListLines] = useState([]);
    const [listMachines, setListMachines] = useState([]);
    const [listIdProducts, setListIdProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listStaffs, setListStaffs] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [listErrors, setListErrors] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        date: [dayjs(), dayjs()]
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [dateType, setDateType] = useState('date');

    const columns2 = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            width: '5%'
        },
        {
            title: 'Thời gian kiểm tra',
            dataIndex: 'thoi_gian_kiem_tra',
            key: 'thoi_gian_kiem_tra',
            align: 'center',
            width: '15%',
        },
        {
            title: 'Tên SP',
            dataIndex: 'ten_san_pham',
            key: 'ten_san_pham',
            align: 'center',
            width: '15%',
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
            width: '20%',
        },
        {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
            width: '10%',
        },
        {
            title: 'Mã pallet/thùng',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
            width: '15%',
        },
        {
            title: 'Phán định',
            dataIndex: 'phan_dinh',
            key: 'phan_dinh',
            align: 'center',
            render: (value) => value === 2 ? 'NG' : value === 1 ? 'OK' : "",
            width: '10%',
        },
        {
            title: 'Người kiểm tra',
            dataIndex: 'user_name',
            key: 'user_name',
            align: 'center',
            width: '15%',
        },
    ];
    useEffect(() => {
        (async () => {
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
        btn_click();
    }, []);
    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [colNumber, setColNumber] = useState(0);
    const loadDataTable = async (params) => {
        setLoading(true)
        const res = await getIQCList(params);
        const col = [];
        let counter = 0;
        Object.keys(res.data.columns ?? {}).map(key => {
            col.push({
                title: key, key: key, children: res.data.columns[key].map(e => {
                    counter++
                    return { title: e?.title, key: e?.key, dataIndex: e?.key, align: 'center' }
                }
                )
            });
        });
        setColNumber(counter)
        setData(res.data.data);
        setTotalPage(res.data.totalPage);
        setLoading(false)
    }
    async function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page: page, pageSize: pageSize });
    }

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportIQC(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 8px' }} layout="vertical">
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value });
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                            <Form.Item label="Lô Sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập lô sản xuất"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        setParams({ ...params, lo_sx: value });
                                    }}
                                    options={listLoSX}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className="custom-col">
                    <Row gutter={[8, 8]} style={{ height: '100%' }}>
                        <Col span={24}>
                            <Card title="Danh sách kiểm tra NVL"
                                style={{ padding: '0px', height: '100%' }}
                                bodyStyle={{ padding: 12 }}
                                extra={<Button type='primary' loading={exportLoading} onClick={exportFile}>Xuất excel</Button>}
                            >
                                <Table bordered
                                    className="your-table-name"
                                    loading={loading}
                                    columns={columns2}
                                    dataSource={data}
                                    pagination={{
                                        current: page,
                                        size: 'default',
                                        total: totalPage,
                                        onChange: (page, pageSize) => {
                                            btn_click(page, pageSize)
                                        }
                                    }}
                                    tableLayout='auto'
                                    scroll={
                                        {
                                            // x: 100 + (columns.length + colNumber - columns2.length) * 7 + '%',
                                            y: '66vh'
                                        }}
                                    size="small" />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default QualityIQC;
