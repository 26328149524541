import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    RESET_LOGIN_FLAG
  } from "./actionTypes";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const login = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_SUCCESS:
        state = {
          ...state,
          loading: false,
        };
        break;
      case LOGOUT_USER:
        state = { ...state, isUserLogout: false };
        break;
      case LOGOUT_USER_SUCCESS:
        state = { ...state, isUserLogout: true };
        break;
      case API_ERROR:
        state = {
          ...state,
          error: action.payload.data,
          loading: false,
          isUserLogout: false,
        };
        break;
      case RESET_LOGIN_FLAG:
        state = {
          ...state,
          error: null
        };
        break;
      default:
        state = { ...state };
    }
    return state;
  };
  
  export default login;
  