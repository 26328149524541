import { Form, Modal, Row, Col, Button, Divider, Radio, Space, InputNumber, Input, message } from 'antd';
import React, { useState } from 'react';
import './popupStyle.scss'
import { useEffect } from 'react';
import { getChecksheetList, getHistoryChecksheet, sendResultChecksheet } from '../../api/oi/quality';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const Checksheet2 = (props) =>{
    const [messageApi, contextHolder] = message.useMessage();
    const {line} = useParams();
    const {text, checksheet = [], lotId,  setOpenKV = null, changeVariable = null, disabled = false, keyName, onComplete = null} = props;
    const closeModal = () =>{
        setOpen(false);
    }
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const result = Form.useWatch('result', form);
    const [checksheetHistory, setChecksheetHistory] = useState()
    const onFinish = async (values) =>{
        setChecksheetHistory(values)
        // if(lotId){
            values.key = checksheet?.key?.key;
            values.lot_id = lotId;
            values.line_id = line;
            console.log(values);
            var res = await sendResultChecksheet(values);
            closeModal();
        // }else{
        //     console.log('kkhong co ma pallet');
        // }
        onComplete()
    }
    const hanleClickOk = () =>{
        form.setFieldValue('result', 1);
        form.submit();
    } 
    const hanleClickNG = () =>{
        form.setFieldValue('result', 0);
        form.submit();
    }
    const hanleClickKV = () =>{
        closeModal();
        setOpenKV(true)
    }
    useEffect(()=>{
        if(checksheet?.data?.length > 0){
            form.setFieldsValue({data: (checksheet.data ?? []).map(e=>{return {id: e.id}}), result: ''});
        }
        console.log(checksheet);
    }, [checksheet, line, changeVariable])
    const onSubmitFail = ({ values, errorFields, outOfDate }) => {
        // console.log(values, errorFields, outOfDate);
        messageApi.error('Chưa hoàn thành chỉ tiêu kiểm tra')
    }
    useEffect(()=>{
        if(lotId){
            (async ()=>{
                var res = await getHistoryChecksheet({key_name: keyName, line_id: line, lot_id: lotId})
                setChecksheetHistory(res[keyName]);
            })()
        }else{
            setChecksheetHistory();
        }
    }, [lotId, changeVariable])
    return (
        <React.Fragment>
            {contextHolder}
            <Button disabled={!(checksheet.data ?? []).length > 0 || disabled} type={(checksheetHistory) ? "primary" : 'default'} danger={(checksheetHistory?.result === 0)} size='large' className='w-100 text-wrap h-100' onClick={() => ((!checksheetHistory  || checksheetHistory?.result === 0) && !(checksheetHistory?.result === 0 && line === "16")) && setOpen(true)}>
                {text}
            </Button>
            <Modal 
                title={"Kiểm tra "+(checksheet?.key?.title)}
                open={open}  
                onCancel={closeModal}
                footer={
                    <div className='justify-content-between d-flex'>
                        <strong>Kết luận</strong>
                        <Space>
                            <Button type='primary' style={{backgroundColor:'#55c32a'}} onClick={hanleClickOk}>Duyệt</Button>
                            {/* <Button type='primary' style={{backgroundColor:'#f7ac27'}} onClick={hanleClickKV}>Khoanh vùng</Button> */}
                            <Button type='primary' style={{backgroundColor:'#fb4b50'}} onClick={hanleClickNG}>NG</Button>
                        </Space>
                    </div>
                }
                width={700}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onSubmitFail}
                    initialValues={{
                        data: (checksheet.data ?? []).map(e=>{return {id: e.id}})
                    }}
                    colon={false}
                    // {...formItemLayout}
                >
                    <Form.Item hidden name={'result'}></Form.Item>
                    <Form.List name={'data'}>
                        {(fields, { add, remove }, { errors }) => 
                        (checksheet.data ?? []).map((e, index)=>{
                            if((e.input)){
                                return (
                                    <Row gutter={8} className={index === 0 ? '' : 'mt-2'}>
                                        <Col span={12} style={{paddingInline:4}} className='d-flex justify-content-center flex-wrap align-items-lg-center'>
                                            <div className='d-flex justify-content-center align-content-center flex-grow-1 align-items-lg-center p-2' style={{backgroundColor:'#EBEBEB', height:'100%', flexWrap:'wrap'}}>
                                                {e.hang_muc}{e.note.trim() && ('. ('+e.note+')')}
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item noStyle name={[index, 'value']} rules={[{required: true}]}>
                                                <InputNumber className=' text-center h-100 d-flex align-items-center justify-content-center' inputMode='numeric' placeholder='Nhập số' style={{width:'100%'}} onChange={(value)=>form.setFieldValue(['data', index, 'result'], (parseFloat(value) >= (parseFloat(e.tieu_chuan) - parseFloat(e. delta)) && value <= (parseFloat(e.tieu_chuan) + parseFloat(e. delta))) ? 1 : 0)}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item name={[index, "id"]} hidden noStyle></Form.Item>
                                            <Form.Item noStyle shouldUpdate={(prevVal, curVal)=> prevVal.data[index]?.value !== curVal.data[index]?.value}>
                                            {({ getFieldValue }) =>
                                                <Form.Item name={[index, 'result']} noStyle className='w-100 h-100'>
                                                    {!getFieldValue(['data',index, "value"]) ? 
                                                    <Button className='w-100 text-center h-100 d-flex align-items-center justify-content-center'>OK/NG</Button>
                                                    :
                                                    getFieldValue(['data',index, "result"]) ? 
                                                    <Button className='w-100 text-center h-100 d-flex align-items-center justify-content-center' style={{backgroundColor:'#55c32a', color: 'white'}}>OK</Button>
                                                    : 
                                                    <Button className='w-100 text-center h-100 d-flex align-items-center justify-content-center' style={{backgroundColor:'#fb4b50', color: 'white'}}>NG</Button>}
                                                </Form.Item>
                                            }
                                                
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }else{
                                return (
                                    <Row gutter={8} className={index === 0 ? '' : 'mt-2'}>
                                        <Col span={12} style={{paddingInline:4}} className='d-flex justify-content-center flex-wrap align-items-lg-center'>
                                            <div className='d-flex justify-content-center align-content-center flex-grow-1 align-items-lg-center p-2' style={{backgroundColor:'#EBEBEB', height:'100%', flexWrap:'wrap'}}>
                                                {e.hang_muc}{e.tieu_chuan?.trim() && ('. ('+e.tieu_chuan+')')}
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={[index, "id"]} hidden noStyle></Form.Item>
                                            <Form.Item name={[index, 'result']} noStyle rules={[{required: true}]}>
                                                <Radio.Group 
                                                    style={{float:'right', width:'100%', height:'100%'}}
                                                    className='d-flex'
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                >
                                                    <Radio.Button value={1} className={'positive-radio text-center h-100 d-flex align-items-center justify-content-center'} style={{flex:1}}>OK</Radio.Button>
                                                    <Radio.Button value={0} className='negative-radio text-center h-100 d-flex align-items-center justify-content-center' style={{flex:1}}>NG</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            }
                        })}
                    </Form.List>
                    <Divider></Divider>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Checksheet2;